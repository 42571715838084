@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
body, html {
    font-family: 'Montserrat', sans-serif;
}

/* Swal */
.swal2-actions button{
    background-color: transparent!important;
    padding:0!important;
}
.swal2-actions button:hover, .swal2-actions button:active, .swal2-actions button:focus{
    background-image: none!important;
}
.DraftEditor-root{
    padding: 0;
}
.public-DraftStyleDefault-block{
    margin: 0!important;
}
.rdw-option-wrapper{
    font-weight: 400!important;
    color: #212529!important;
    text-align: center!important;
    vertical-align: middle!important;
    -webkit-user-select: none!important;
            user-select: none!important;
    background-color: transparent!important;
    border: 1px solid transparent!important;
    padding: 0.275rem 0.45rem!important;
    font-size: 1rem!important;
    line-height: 1.5!important;
    border-radius: 0.25rem!important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;
}
.rdw-dropdown-wrapper{
    border: none!important;
}
.rdw-option-wrapper:hover, .rdw-dropdown-wrapper:hover{
    box-shadow: none!important;
    background-color: #ced4da!important;
}
.rdw-option-active, .rdw-dropdown-wrapper-active{
    box-shadow: none!important;
    background-color: #ced4da!important;
}
.editor-toolbar{
    margin-bottom: 0px!important;
    border: none;
    border-bottom: 1px solid #ced4da;
}
.editor-wrapper{
    border-radius: 0.25rem!important;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-width: 1px;
}
/*border: ${props => props['data-success'] ? '1px solid #28a745' : props['data-error'] ? '1px solid #dc3545' : '1px solid #ced4da'};*/
.public-DraftEditor-content{
    padding: 0.375rem 0.75rem;
    background-color: rgb(182, 227, 236)!important;
    border-bottom-right-radius: 0.25rem!important;
    border-bottom-left-radius: 0.25rem!important;
}
.editor-editor:focus, .editor-editor:active, .public-DraftEditor-content:focus{
    background-color: white!important;
}
