@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

body, html {
    font-family: 'Montserrat', sans-serif;
}

/* Swal */
.swal2-actions button{
    background-color: transparent!important;
    padding:0!important;
}
.swal2-actions button:hover, .swal2-actions button:active, .swal2-actions button:focus{
    background-image: none!important;
}